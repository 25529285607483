import { lazy, Suspense } from 'react';
import AppSuspenseScreen from './AppSuspenseScreen';
const App = lazy(() => import(/* webpackChunkName: "App" */'./App'));

const AppPreloader = () => (
    <>
        <Suspense fallback={<AppSuspenseScreen />}><App /></Suspense>
    </>
  );

export default AppPreloader;
