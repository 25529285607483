import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter  } from 'react-router-dom';
import AppPreloader from './AppPreloader';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

root.render(<React.StrictMode><BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE}><AppPreloader /></BrowserRouter></React.StrictMode>);

/*ReactDOM.render(
  <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE} >
    <ScrollToTop />
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </BrowserRouter>,
  document.getElementById('root')
);*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);